import React, { useEffect, useState } from "react"

import * as styles from "./hero.module.scss"

const isClient = () => typeof window !== 'undefined' && typeof document !== 'undefined'

const Hero = ({children, colour, isWide, forceVerticalCentre}) => {
    const calculateMinHeight = () => {
        if (!isClient()) {
            return null
        }
        const height = document.documentElement?.clientHeight || window.innerHeight  // copes with iOS address bar etc
        const width = window.innerWidth  // exclusive care about it being inner width
        const padding = width >= 769 ? "3.25rem" : "6.5rem"
        return { minHeight: `calc(${height}px - ${padding})` }
    }
    const [minHeight, setMinHeight] = useState(calculateMinHeight())
    useEffect(() => {
        function updateHeight() {
            setMinHeight(calculateMinHeight())
        }

        window.addEventListener('resize', updateHeight)
        return () => window.removeEventListener('resize', updateHeight)
    }, [])

    return (
        <>
            <section className={`hero is-${colour} ${styles.hero}`} style={minHeight}>
                <div className={`hero-body ${styles.body} ${forceVerticalCentre ? styles.vCentre : ""}`}>
                    <div className={`container${isWide ? '' : ' is-max-desktop'}`}>
                        {children}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero
