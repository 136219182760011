import * as React from "react"

import Hero from "../components/hero"
import LinkedInIcon from "../components/linkedin-icon"

const TeamPage = () => {
    return (
        <>
            <Hero colour={"dark"}>
                <div className="content">
                    <div className="title is-size-2-desktop has-text-centered">
                        WHO ARE WE?
                    </div>
                    <div className="columns has-text-centered">
                        <div className="column">
                            <h4 className="title is-5">Michael Peake</h4>
                            <p>
                                {/* Colours hacked from variables.scss */}
                                <LinkedInIcon url={"https://www.linkedin.com/in/michaelpeake/"} colour="#f7f7ff" backgroundColour="#363636" />
                            </p>
                            <p>
                                Michael is full-stack developer and startup generalist, having experienced most aspects of small tech businesses.
                                Previously a researcher in computational engineering, Michael moved into startups as a co-founder and developer.
                                He has since worked with a number of firms with clients ranging from Formula 1 and elite Olympic sports teams, to manufacturers of augment reality hardware.
                            </p>
                            <p>
                                One of Michael's most valuable strength is his ability to understand problems from different perspectives, whether it's a tech lead, CEO or customer.
                                He is product-focused, considering each decision or development against the value it brings to that product.
                            </p>
                        </div>
                        <div className="column">
                            <h4 className="title">Zoe Burton</h4>
                            <p>
                                <LinkedInIcon url={"https://www.linkedin.com/in/zoe-burton-69230ba5/"} colour="#f7f7ff" backgroundColour="#363636" />
                            </p>
                            <p>
                                Zoe is a data scientist and analyst, specialising in free-to-play video games.
                                Formerly working in F1 aerodynamics, Zoe pursed a love of data by joining a
                                video games studio, working in a small team on dashboard development,
                                exploratory data analysis and predictive models.
                                As a freelance analyst, the scope of Zoe's experience includes multiple games
                                titles, from studios around the world, and a social network.
                            </p>
                            <p>
                                Zoe's talent is finding the stories hidden within complex data and presenting
                                them in a way that enables informed decision making.
                            </p>
                        </div>
                    </div>
                </div>
            </Hero>
        </>
    )
}

export default TeamPage
